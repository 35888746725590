const baseUrl = "/api/ai";

type AiApiType = {
  getConnectionStringUrl: () => string;
};

const getConnectionStringUrl = () => `${baseUrl}/getConnectionString`;

export default {
  getConnectionStringUrl,
} as AiApiType;
