import React, { Dispatch, useState } from "react";
import { Container } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { useTranslation } from "react-i18next";
import DragAndDrop, {
  DndError,
  DndState,
} from "../../DragAndDropV2/DragAndDrop";
import * as XLSX from "xlsx";
import { isEmpty } from "underscore";
import { ICheckParkingRight } from "../../DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { connect } from "react-redux";
import useAppContext from "../../../context/hooks/useAppContext";
import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";

const { typography } = variables;

const dprSheetName = "Import Vehicles";
const countryName = "Country";
const numberPlateName = "Number Plate";
const descriptionName = "Description";
const rowNumber = "__rowNum__";

const VehiclesImportDragAndDrop: React.FC<VehiclesImportDragAndDropProps> = (
  props
) => {
  const [state, setState] = useState<DndState>("default");
  const [error, setError] = useState<DndError | undefined>(undefined);
  const { appState } = useAppContext();

  const { importVehiclesState: ImportVehiclesState } = useImportVehiclesContext();

  const { t } = useTranslation(["whiteListVehicleForm"]);
  const supportedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleFileUpload = (file: File) => {
    setState("processing");
    props.onProcessing(true);
    readVehiclesFromXls(file);
  };

  const readVehiclesFromXls = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = handleUploadedData;
  };

  const handleUploadedData = (e: ProgressEvent<FileReader>) => {
    const vehiclesToImport: VehicleData[] = [];

    const data = e.target?.result;
    const wb = XLSX.read(data, { type: "binary" });

    if (wb.SheetNames && wb.SheetNames.includes(dprSheetName)) {
      readDataFromExcel(wb, vehiclesToImport);

      const fileIsEmpty = isEmpty(vehiclesToImport);
      if (fileIsEmpty) {
        setState("error");
        setError("empty");
        props.onProcessing(false);
        return;
      }

      const checkParkingRights = [...ImportVehiclesState.checkParkingRights];
      checkParkingRights.forEach((checkParkingRight) => {
        checkParkingRight.right!.totalDelegatedParkingRights! +=
          vehiclesToImport.length;
      });
      
    } else {
      setState("error");
      setError("invalid");
    }

    // TODO upload file to backend
  };

  const readDataFromExcel = (wb: XLSX.WorkBook, vehiclesToImport: VehicleData[]) => {
    const rows: any[] = XLSX.utils.sheet_to_json(wb.Sheets[dprSheetName]);
    rows.forEach((row) => {
      if (row[countryName] || row[numberPlateName] || row[descriptionName]) {
        let vehicle: VehicleData;
        vehicle = {
          country: { value: row[countryName], isValid: true },
          numberPlate: { value: row[numberPlateName], isValid: true },
          description: { value: row[descriptionName], isValid: true },
          lineNumber: row[rowNumber] + 1
        };
        vehiclesToImport.push(vehicle);
      }
    });
  }


  if (!props.show) {
    return null;
  }

  return (
    <React.Fragment>
      {error === undefined && (<Container>
        <NoPaddingBottomGrid container item direction="row" alignItems="center">
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <Heading fontSize={typography.fontSizeBase}>
              <strong>{t("uploadFile")}</strong>
            </Heading>
          </NoPaddingBottomGrid>
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <div className="download-template">
              <div>
                <a
                  href="/files/VehicleImportTemplate.xlsx"
                  target="_blank"
                  download
                >
                  {t("downloadTemplate")}
                </a>
                <KeyboardArrowDown />
              </div>
            </div>
          </NoPaddingBottomGrid>
        </NoPaddingBottomGrid>
      </Container>)}
      <Container className="h-full">
        <DragAndDrop
          onFileUploaded={handleFileUpload}
          supportedFileTypes={supportedFileTypes}
          state={state}
          error={error}
          seasonTicketOwnerCrmId={appState.user.seasonTicketOwnerCrmId as string}
          fileReference={undefined}
          invalidDelegeesCount={undefined}
          validateDelegeesErrorCode={undefined}
          generalValidationErrors={undefined}
          showMaxFileSize
        />
      </Container>
    </React.Fragment>
  );
};


interface VehiclesImportDragAndDropProps {
  show: boolean;
  onFileProcessed: (data: VehicleData[]) => void;
  onCheckedParkingRights: (pr: ICheckParkingRight[]) => void;
  onProcessing: (processing: boolean) => void;
}

export default connect(null)(VehiclesImportDragAndDrop);
