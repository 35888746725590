import React from "react";
import IChildren from "./interfaces/IChildren";
import { connect } from "react-redux";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

interface IProps extends IChildren {
    aiConnectionString: string;
}

const reactPlugin = new ReactPlugin();

const TelemetryProvider = ({ children, aiConnectionString }: IProps) => {
    const browserHistory = createBrowserHistory({ basename: '' });
    if (aiConnectionString) {
        const appInsights = new ApplicationInsights({
            config: {
                connectionString: `${aiConnectionString}`,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                }
            }
        });
        appInsights.loadAppInsights();
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

export default connect()(withAITracking(reactPlugin, TelemetryProvider));