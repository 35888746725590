import { TFunction } from "i18next";
import * as Yup from "yup";
import { format } from "../../../utils";
import { useFormik } from "formik";
import { useMemo } from "react";

export interface IWhitelistVehiclesFormValues {
  products?: number[];
}

export function createYupSchema(t: TFunction, defaultNumberPlatePrefix = "NL") {
  const productValidationSchema = Yup.array(Yup.number())
        .min(1, format(t("errors:min.field"), t("globals:parkingRights"), 1))
        .required(format(t("errors:required.field"), t("globals:parkingRights").toLowerCase()))
        .default([]);

  return Yup.object({ products: productValidationSchema });
}

const initialValues: IWhitelistVehiclesFormValues = {
  products: [],
};

const useValidationSchema = (
  t: TFunction,
  onSubmit: (values: IWhitelistVehiclesFormValues) => void,
) => {
  const validationSchema = useMemo(() => createYupSchema(t), [t]);

  const formik = useFormik<IWhitelistVehiclesFormValues>({
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: initialValues,
  });

  return {
    validationSchema,
    formik,
  };
};

export default useValidationSchema;
