import React, { Dispatch, useEffect, useRef } from "react";
import { Container, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import Heading from "../../ui/Heading/Heading";
import { variables } from "../../theme/variables";
import { CloseOutlined } from "@material-ui/icons";
import CustomButton from "../../ui/CustomButton/CustomButton";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";
import useSmartState from "../../utils/hooks/useSmartState";
import DelegeesImportDragAndDrop from "./DelegeesImportDragAndDrop/DelegeesImportDragAndDrop";
import ActionDrawer from "../../ui/ActionDrawer/ActionDrawer";
import CloseDelegeeImportWarning from "../Warnings/CloseImportWarning";
import useImportDelegationsContext from "../../context/hooks/useImportDelegationsContext";
import { DelegeeData } from "../../context/store/reducers/importDelegations.reducer";
import { isEmpty } from "underscore";
import useAppContext from "../../context/hooks/useAppContext";
import { RootReducer } from "../../store/reducers";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import "../../scss/_uploadView.scss";
import BulkDelegationView from "./BulkDelegationView/BulkDelegationView";
import { StartBulkInvitationsRequestModel } from "../../models/bulkImport/StartBulkInvitationsRequestModel";

const { typography } = variables;

const MultipleDelegeesForm: React.FC<MultipleDelegeesFormProps> = ({
  onClose,
  startBulkInvitations,
  validateDelegees,
  hasErrors,
  bulkImportProgress
}) => {
  const executed = useRef(false);
  const initialState: DelegeesDndState = {
    step: DndStep.FileUpload,
    afterWarningConfirmationAction: onClose,
    showOnlyErrors: true,
    showConfirmationScreen: false,
  };

  const { appState } = useAppContext();
  const {
    importDelegationsState,
    setDelegeesData,
    setCheckParkingRights,
    setProcessing,
  } = useImportDelegationsContext();
  const {
    fleetManagerState,
    setDragAndDropView,
    toggleCloseImportWarning: toggleCloseDelegeeImportWarning,
  } = useFleetManagerContext();
  const { t } = useTranslation(["delegeeForm", "globals"]);

  const { state, updateState } = useSmartState<DelegeesDndState>(initialState);

  const goToSelection = () => setDragAndDropView(false);

  const submitDelegees = () => {
    const pmcIds = importDelegationsState.checkParkingRights.map(
      (pr) => pr.right?.pmcId!
    );

    if (validateDelegees.success && validateDelegees.fileReference) {
      updateState({ ...state, step: DndStep.BulkInProgress })
      startBulkInvitations(appState.user.seasonTicketOwnerCrmId as string, validateDelegees.fileReference, { pmcIds }, () => {
        setDelegeesData([]);
      });
    }
  };

  const setAfterWarningConfirmationAction = (
    afterWarningConfirmationAction: () => void
  ) => updateState({ afterWarningConfirmationAction });

  useEffect(() => {
    if (isEmpty(importDelegationsState.data)) {
      return;
    }

    if (executed.current) {
      return;
    }

    updateState({
      showConfirmationScreen: true,
      //step: DndStep.TableData,
    });

    executed.current = true;
  }, [importDelegationsState.data]);

  const shouldShowDataRemovalWarning = () => {
    return (
      importDelegationsState.processing ||
      (importDelegationsState.data &&
        importDelegationsState.data.filter(
          (d) => d.name.value !== "" || d.emailAddress.value !== ""
        ).length > 0)
    );
  };

  const handleOnCloseDelegeeForm = () => {
    if (shouldShowDataRemovalWarning()) {
      setAfterWarningConfirmationAction(handleCloseDelegeeForm);
      toggleCloseDelegeeImportWarning();
      return;
    }

    handleCloseDelegeeForm();
  };

  const resetCheckParkingRights = () => {
    const checkParkingRights = [...importDelegationsState.checkParkingRights];

    checkParkingRights.forEach((checkParkingRight, index) => {
      const rowsWithValues = importDelegationsState.data.filter(
        (d: DelegeeData) => {
          return d.name.value || d.emailAddress.value;
        }
      );
      checkParkingRight.right!.totalDelegatedParkingRights! -=
        rowsWithValues.length;
    });

    setCheckParkingRights(checkParkingRights);
  };

  const handleCloseDelegeeForm = () => {
    resetCheckParkingRights();
    setDelegeesData([]);
    onClose();
  };

  const executeActionWithPossibleWarning = (action: () => void) => {
    if (shouldShowDataRemovalWarning()) {
      setAfterWarningConfirmationAction(action);
      toggleCloseDelegeeImportWarning();
    } else {
      action();
    }
  }

  const handlePreviousAction = () => {
    switch (state.step) {
      case DndStep.FileUpload:
        executeActionWithPossibleWarning(goToSelection)
        break;
      case DndStep.BulkInProgress:
        handleCloseDelegeeForm();
        break;
    }
  };

  const previousActionLabel = () => {
    switch (state.step) {
      case DndStep.FileUpload:
        return t("backToSelection");
      case DndStep.BulkInProgress:
        return t("close");
    }
  }

  const isCloseDisabled = () => {
    return bulkImportProgress.progress !== 100 && state.step === DndStep.BulkInProgress;
  }

  const bulkUploadIsFinished = () => {
    return bulkImportProgress.progress === 100;
  }

  return (
    <ActionDrawer
      open={
        fleetManagerState.showDelegeeDetails &&
        fleetManagerState.dragAndDropView
      }
      onClose={handleOnCloseDelegeeForm}
      className="delegee-form-drawer"
    >
      <div className="upload-view-dnd-container">
        <div className="upload-view-dnd-header">
          <Container className="upload-view-dnd-header-content">
            <Heading
              width="auto"
              justifyContent="center"
              fontSize={typography.fontSizeLarge}
            >
              {t("addMultipleDelegees")}
            </Heading>
            <Button
              onClick={handleOnCloseDelegeeForm}
              classes={{ root: "close-icon-btn" }}
              disableRipple
            >
              <CloseOutlined classes={{ root: "close-icon" }} />
            </Button>
          </Container>
          <Container className="upload-view-dnd-header-stepper">
            <Stepper className="steps" color="#8bb611">
              <Step
                active={state.step === DndStep.FileUpload}
                completed={state.step! > DndStep.FileUpload}
              >
                <StepLabel />
              </Step>
              {(
                <Step
                  active={state.step === DndStep.BulkInProgress}
                  completed={state.step === DndStep.BulkInProgress && bulkUploadIsFinished()}
                >
                  <StepLabel />
                </Step>
              )
              }
            </Stepper>
          </Container>
        </div>
        <div className="upload-view-dnd-main">
          <div className="h-full">
            <DelegeesImportDragAndDrop
              show={state.step === DndStep.FileUpload}
              onFileProcessed={(data) => setDelegeesData(data)}
              onProcessing={(processing) => setProcessing(processing)}
              onCheckedParkingRights={(pr) => setCheckParkingRights(pr)}
            />
            {
              state.step === DndStep.BulkInProgress &&
              (<BulkDelegationView />)
            }
          </div>
        </div>
        <div className="upload-view-dnd-footer">
          <Container className="upload-view-dnd-footer-content">
            <CustomButton.Light
              onClick={handlePreviousAction}
              text={previousActionLabel()}
              disabled={isCloseDisabled()}
            />
            {(
              <CustomButton.Primary
                onClick={submitDelegees}
                text={t("addDelegees")}
                disabled={
                  importDelegationsState.data.every((d) => isEmpty(d.name.value) && isEmpty(d.emailAddress.value) && hasErrors) ||
                  state.step === DndStep.BulkInProgress
                }
              />
            )}
          </Container>
        </div>
        <ActionDrawer
          open={fleetManagerState.showCloseImportWarning}
          onClose={toggleCloseDelegeeImportWarning}
          hideBackdrop={true}
        >
          <CloseDelegeeImportWarning
            onConfirmation={state.afterWarningConfirmationAction ?? onClose}
          />
        </ActionDrawer>
      </div>
    </ActionDrawer>
  );
};

enum DndStep {
  FileUpload = 0,
  BulkInProgress = 1,
}

interface DelegeesDndState {
  step?: DndStep;
  afterWarningConfirmationAction?: () => void;
  showOnlyErrors?: boolean;
  showConfirmationScreen?: boolean;
}

interface MultipleDelegeesFormProps extends StateProps, DispatchProps {
  onClose: () => void;
}

const mapStateToProps = (state: RootReducer) => {
  const hasErrors = state.parkingProducts.error != null;
  const { validateDelegees, bulkImportProgress } =  state.parkingProducts;

  return {
    validateDelegees,
    hasErrors,
    bulkImportProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  startBulkInvitations: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    request: StartBulkInvitationsRequestModel,
    onSuccess: VoidFunction
  ) =>
    dispatch(
      parkingProductsActions.startBulkInvitations(
        seasonTicketOwnerCrmId,
        fileReference,
        request,
        onSuccess
      )
    )
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleDelegeesForm);
