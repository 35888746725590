import React, { useEffect, Dispatch, useState } from "react";
import { connect } from "react-redux";
import { isEmpty, isNull } from "underscore";
import Warning from "./Warning";
import useAppContext from "../../context/hooks/useAppContext";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { RootReducer } from "../../store/reducers";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";

interface IFormProps extends StateProps, DispatchProps{
  getWarningText: (parkingProductsCount : number) => string,
  getWarningDetails: (parkingProductsCount : number) => string,
  onRefresh : () => void;
}

const DeleteDelegeeWarning = ({
    deleteDelegee,
    delegeeDetails,
    getDelegeeDetails,
    clearDelegeeDetails,
    getWarningText,
    getWarningDetails,
    onRefresh
} : IFormProps) => {
  const { t } = useTranslation("globals");

  const { fleetManagerState, toggleDeleteDelegeeWarning, setSelectedRegistrationId, toggleEditDelegee} = useFleetManagerContext();
  const { appState } = useAppContext();

  const disabled = delegeeDetails.data == null;
  const parkingRightsCount = delegeeDetails.data?.products.length ?? 0;
  const warningText = getWarningText(parkingRightsCount); 
  const warningDetails = getWarningDetails(parkingRightsCount); 

  const initWarning = () => {
    const shouldGetdelegeeDetails = isNull(delegeeDetails.data) || delegeeDetails.data?.registrationId !== fleetManagerState.selectedRegistrationId;
      if(shouldGetdelegeeDetails && !isEmpty(fleetManagerState.selectedRegistrationId)){
            getDelegeeDetails(fleetManagerState.selectedRegistrationId, appState.selectedLanguage);
      }
  }

  const resetWarning = () => {
      const shouldCleardelegeeDetails = !fleetManagerState.showDelegeeDetails;
      if(shouldCleardelegeeDetails){
        clearDelegeeDetails();
        setSelectedRegistrationId("");
      }
  }

  useEffect(() => {
      initWarning();

      return () => {
        resetWarning();
      }
  
    // eslint-disable-next-line
  }, []);

  const handleDeleteCancelation = () => {
    toggleDeleteDelegeeWarning();
  }

  const handleSuccessfulDelete = () => {
      toggleDeleteDelegeeWarning();

      if(fleetManagerState.showDelegeeDetails){
        toggleEditDelegee("");
      }

      onRefresh();
  }

  const [loading, setLoading] = useState(false);

  const handleDeleteConfirmation = () => {
      const registrationId = fleetManagerState.selectedRegistrationId;

      setLoading(true);
      deleteDelegee(registrationId, () => {
        setLoading(false);
        handleSuccessfulDelete()
      });
  }

  return (
          <Warning
              warning={warningText}
              details={warningDetails}
              onCancel={handleDeleteCancelation}
              onConfirm={handleDeleteConfirmation}
              disabled={disabled}
              confirmText={t("delete")}
              loading={loading}
          />
        );
};

const mapStateToProps = (state: RootReducer) => {
  const { delegeeDetails } = state.parkingProducts;

  return {
    delegeeDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  deleteDelegee: (registrationId: string, callback? : () => void) =>
    dispatch(parkingProductsActions.deleteDelegee(registrationId, callback)),

  getDelegeeDetails: (registrationId : string, language: string) => 
    dispatch(parkingProductsActions.getDelegeeDetails(registrationId, language)),
    
  clearDelegeeDetails: () =>
    dispatch(parkingProductsActions.clearDelegeeDetails()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDelegeeWarning);
