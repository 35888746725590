import React, { useRef } from "react";
import { CardContent, CardMedia, Grid } from "@material-ui/core";
import Heading from "../../ui/Heading/Heading";
import EllipsedSpan from "../../ui/EllipsedSpan/EllipsedSpan";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import ProductsTooltip from "./ProductsTooltip/ProductsTooltip";
import { isEmpty } from "underscore";
import Skeleton from "@material-ui/lab/Skeleton";
import locationMarker from "../../assets/images/location-marker.svg";
import seasonTicket from "../../assets/images/season-ticket.svg";
import vehicle from "../../assets/images/vehicle.svg";
import NonBreakableSpace from "../../ui/NonBreakableSpace/NonBreakableSpace";
import {
  Inline,
  LocationCapacity,
  NoPaddingCol,
  StyledSkeleton,
  StyledCard,
  useStyles,
} from "./LocationCardStyledComponents";
import locationCardBusiness, { Position } from "./locationCard.business";
import { selectUseBulkVehicleAssignments, useGetOccupiedParkingRightsCount } from "../../containers/FleetManager/MyProducts/ParkingRightsDataTable/common";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";

interface ILocationCardProps {
  model: LocatedParkingProduct;
  onClick: (position?: Position) => void;
  loading?: boolean;
}

const LocationCard: React.FC<ILocationCardProps> = ({ model, loading, onClick }) => {
  const {
    locationName,
    cityName,
    imageSrc,
    totalParkingRights,
    totalPmcs,
    parkingProducts
  } = model;

  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();

  const { t } = useTranslation(["vehicleAssignment"]);
  const classes = useStyles();
  const instance = useRef<any>(null);
  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);

  const onCardClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    locationCardBusiness.handleCardClicked(event, instance, (pos: Position | undefined) =>
      onClick(pos as Position)
    );

  const hasUnlimitedEntryRights = parkingProducts.some((p) => p.hasUnlimitedEntryRights);

  return (
    <StyledCard
      data-card-type="location-card"
      className={classes.root}
      ref={instance}
      onClick={onCardClicked}
    >
      <CardMedia
        className={classes.media}
        image={!imageSrc ? "undefined" : imageSrc}
        title="city image"
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <EllipsedSpan>
          <Heading>{locationName}</Heading>
        </EllipsedSpan>
        <Grid
          container
          item
          direction="row"
          xs={12}
          classes={{ root: classes.gridRoot }}
          justifyContent="space-between"
        >
          {!isEmpty(cityName) && (
            <NoPaddingCol
              container
              item
              direction="column"
              xs={5}
              classes={{ root: classes.colRoot }}
            >
              <Inline justifyContent="flex-start">
                <embed src={locationMarker} width={16} height={16} />
                <NonBreakableSpace />
                <EllipsedSpan>{cityName}</EllipsedSpan>
              </Inline>
            </NoPaddingCol>
          )}

          <NoPaddingCol
            container
            item
            direction="column"
            xs={2}
            classes={{ root: classes.colRoot }}
          >
            {loading ? (
              <Skeleton />
            ) : (
              <Inline justifyContent="flex-start">
                <embed src={seasonTicket} width={16} height={16} />
                <NonBreakableSpace />
                <span>{totalPmcs}</span>
              </Inline>
            )}
          </NoPaddingCol>
          <NoPaddingCol
            container
            item
            direction="column"
            xs={5}
            classes={{ root: classes.colRoot }}
          >
            {loading ? (
              <StyledSkeleton />
            ) : (
              <Inline justifyContent="flex-start">
                <embed src={vehicle} width={16} height={16} />
                <NonBreakableSpace />
                <ProductsTooltip parkingProducts={parkingProducts} displayAmount={true}>
                  <LocationCapacity>
                    {getTotalOccupiedParkingRights(model)} / {useBulkVehicleAssignments && hasUnlimitedEntryRights ? t("unlimited") : totalParkingRights}
                  </LocationCapacity>
                </ProductsTooltip>
              </Inline>
            )}
          </NoPaddingCol>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

LocationCard.defaultProps = {
  model: {
    locationName: "Location name",
    cityName: "City name",
    placeId: 0,
    imageSrc: "",
    totalDelegatedParkingRights: 0,
    totalParkingRights: 0,
    totalAssignedParkingRights: 0,
    totalPmcs: 0,
    parkingProducts: [],
    hasUnlimitedEntryRights: false,
  },
  onClick: () => { },
} as ILocationCardProps;

export default LocationCard;
