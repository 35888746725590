import "../../../scss/_uploadView.scss";
import React, { Dispatch} from "react";
import { Container, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { CloseOutlined } from "@material-ui/icons";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import CloseImportWarning from "../../Warnings/CloseImportWarning";
import { RootReducer } from "../../../store/reducers";
import { connect } from "react-redux";
import VehiclesImportDragAndDrop from "../VehiclesImportDargAndDrop/VehiclesImportDragAndDrop";
import useSmartState from "../../../utils/hooks/useSmartState";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";
import { isEmpty } from "underscore";
import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";

const { typography } = variables;

const WhiteListVehiclesUploadView = () => {
  const { t } = useTranslation(["whiteListVehicleForm"]);
  const {
    fleetManagerState,
    setVehicleDragAndDropView,
    toggleCloseImportWarning: toggleCloseImportWarning,
  } = useFleetManagerContext();
  
  const closeUploadView = () => {
    setVehicleDragAndDropView(false);
    setVehicleData([]);
    resetCheckParkingRights();
  }

  const initialState: VehicleDndState = {
    step: DndStep.FileUpload,
    afterWarningConfirmationAction: closeUploadView,
    showOnlyErrors: true,
    showConfirmationScreen: false,
  };
  
  const { state, updateState } = useSmartState<VehicleDndState>(initialState);
  const {
    importVehiclesState: ImportVehiclesState,
    setVehicleData,
    setCheckParkingRights,
    setProcessing,
  } = useImportVehiclesContext();

  
  const onClose = () => {
    if (shouldShowDataRemovalWarning()) {
      setAfterWarningConfirmationAction(closeUploadView);
      toggleCloseImportWarning();
      return;
    }

    closeUploadView();
  };

  const shouldShowDataRemovalWarning = () => {
    return (
      ImportVehiclesState.processing ||
      (ImportVehiclesState.data &&
        ImportVehiclesState.data.filter(
          (d) => d.country.value !== "" || d.numberPlate.value !== "" || d.description.value !== ""
        ).length > 0)
    );
  };

  const resetCheckParkingRights = () => {
    const checkParkingRights = [...ImportVehiclesState.checkParkingRights];

    checkParkingRights.forEach((checkParkingRight) => {
      const rowsWithValues = ImportVehiclesState.data.filter(
        (d: VehicleData) => {
          return d.country.value || d.numberPlate.value || d.description.value;
        }
      );
      checkParkingRight.right!.totalDelegatedParkingRights! -=
        rowsWithValues.length;
    });

    setCheckParkingRights(checkParkingRights);
  };

  const setAfterWarningConfirmationAction = (
    afterWarningConfirmationAction: () => void
  ) => updateState({ afterWarningConfirmationAction });

  return (
      <div className="upload-view-dnd-container">
        <div className="upload-view-dnd-header">
          <Container className="upload-view-dnd-header-content">
            <Heading
              width="auto"
              justifyContent="center"
              fontSize={typography.fontSizeLarge}
            >
              {t("whitelistVehicles")}
            </Heading>
            <Button
              onClick={onClose}
              classes={{ root: "close-icon-btn" }}
              disableRipple
            >
              <CloseOutlined classes={{ root: "close-icon" }} />
            </Button>
          </Container>
          <Container className="upload-view-dnd-header-stepper">
            <Stepper className="steps" color="#8bb611">
              <Step
                active={state.step === DndStep.FileUpload}
                completed={state.step! > DndStep.FileUpload}
              >
                <StepLabel />
              </Step>
              {(
                <Step
                active={state.step === DndStep.BulkInProgress}
                completed={state.step === DndStep.BulkInProgress && false}
                >
                  <StepLabel />
                </Step>
              )
              }
            </Stepper>
          </Container>
        </div>
        <div className="upload-view-dnd-main">
          <div className="h-full">
            <VehiclesImportDragAndDrop
              show={state.step === DndStep.FileUpload}
              onFileProcessed={(data) => setVehicleData(data)}
              onProcessing={(processing) => setProcessing(processing)}
              onCheckedParkingRights={(pr) => setCheckParkingRights(pr)}
            />
          </div>
        </div>
        <div className="upload-view-dnd-footer">
          <Container className="upload-view-dnd-footer-content">
          <CustomButton.Light onClick={onClose} text={t("backToSelection")} id="close-invite" />
            {(
              <CustomButton.Primary
                onClick={() => console.log('Upload File')}
                text={t("whitelistVehicles")}
                disabled={ ImportVehiclesState.data.every((d) => isEmpty(d.country.value) && isEmpty(d.numberPlate.value) && isEmpty(d.description.value)) ||
                  state.step === DndStep.BulkInProgress}
              />
            )}
          </Container>
        </div>
        <ActionDrawer
          open={fleetManagerState.showCloseImportWarning}
          onClose={toggleCloseImportWarning}
          hideBackdrop={true}
        >
          <CloseImportWarning
            onConfirmation={state.afterWarningConfirmationAction ?? onClose}
          />
        </ActionDrawer>
      </div>
  );
};

enum DndStep {
  FileUpload = 0,
  BulkInProgress = 1,
}

interface VehicleDndState {
  step?: DndStep;
  afterWarningConfirmationAction?: () => void;
  showOnlyErrors?: boolean;
  showConfirmationScreen?: boolean;
}

const mapStateToProps = (state: RootReducer) => {
  const hasErrors = state.parkingProducts.error != null;
  const { validateDelegees, bulkImportProgress } =  state.parkingProducts;

  return {
    validateDelegees,
    hasErrors,
    bulkImportProgress
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
)(WhiteListVehiclesUploadView);
