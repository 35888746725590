export type ParkingProductModel = {
  id?: number;
  pmcId?: number;
  pmcName: string;
  location: string;
  countryCode?: string;
  placeId?: number;
  totalParkingRights?: number;
  totalDelegatedParkingRights?: number;
  totalAssignedParkingRights?: number
  isFlexBudgetEnabled: boolean;
  flexBudgetInfo?: FlexBudgetInfo;
};

export type FlexBudgetInfo = {
  seasonTicketId: number;
  remainingTime: string;
  unitType: FlexBudgetUnitType
};

export enum FlexBudgetUnitType {
  Hours = 1,
  Quarters = 2,
  Minutes = 3
}
