import React from "react";
import { useTranslation } from "react-i18next";
import Warning from "./Warning";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";

interface IFormProps {
    onConfirmation: () => void;
}

const CloseImportWarning = ({onConfirmation}: IFormProps) => {
    const { t } = useTranslation(["closeImportWarning", "globals"]);
    const { toggleCloseImportWarning } = useFleetManagerContext();

    const handleCloseCancellation = () => {
      toggleCloseImportWarning();
    };

    const handleCloseConfirmation = () => {
      onConfirmation();
      toggleCloseImportWarning();
    };

    return (
      <Warning
        warning={t("warning")}
        details={t("details")}
        onCancel={handleCloseCancellation}
        onConfirm={handleCloseConfirmation}
        disabled={false}
        confirmText={t("globals:close")}
        useOrangeWarning={true}
        width={675}
      />
    );
};

export default CloseImportWarning;
