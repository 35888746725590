import React, { useEffect, Dispatch, useCallback, useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import DelegeeStatus, {
  Statuses,
} from "../../../../components/DelegeeStatus/DelegeeStatus";
import { RootReducer } from "../../../../store/reducers";
import parkingProductsActions from "../../../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import moment from "moment";
import { IDataTableRow } from "../../../../components/DataTable/DataTableBody/DataTableBody";
import useAppContext from "../../../../context/hooks/useAppContext";
import { DelegeeWithProductsModel } from "../../../../models/delegations/DelegatedParkingProductsModel";
import { isUndefined, isNull } from "underscore";
import FleetManagerDataTable, {
  IFleetManagerDataTableModel,
} from "../../FleetManagerDataTable/FleetManagerDataTable";
import {
  toProductsJsx,
} from "../../Helpers/ParkingRightsHelpers";
import { getSelectedParkingRightIds } from "../MyFleetHelpers";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { WithFiltersAndPaging } from "../../../../models/filters/WithFiltersAndPaging";
import { ForFleetManager } from "../../../../models/ForFleetManager";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import filteredColumnObjectBuilder from "../../MyProducts/ObjectBuilders/FilteredColumnObjectBuilder";
import {
  FilterType,
  IFilteredColumn,
} from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import useMyFleetContext from "../../../../context/hooks/useMyFleetContext";
import { FmpFilters } from "../../../../constants/filtering.constants";
import { getParkingProductOptions } from "../../../../helpers/selectOptionHelper";
import PersonIcon from "@material-ui/icons/Person";
import VehicleIcon from "../../../../ui/Icons/Vehicle";

export interface IDelegeeDataTableModel extends IFleetManagerDataTableModel {
  products: JSX.Element | null;
}

interface IDelegeesDataTable extends StateProps, DispatchProps {
  onAssignVehicleClicked: () => void;
  onInviteFleetMemberClicked: () => void;
  onRevokeClicked: () => void;
  onDeleteClicked: () => void;
  subHeading?: ReactNode;
}

const _createData = (
  delegeeWithRights: DelegeeWithProductsModel
): IDelegeeDataTableModel => {
  return {
    rowKey: delegeeWithRights.registrationId,
    name: delegeeWithRights.name,
    email: delegeeWithRights.emailAddress,
    products: toProductsJsx(delegeeWithRights.products),
    invitationDate: moment(delegeeWithRights.invitationDate).format("DD/MM/YYYY"),
    status: (
      <DelegeeStatus
        type={
          delegeeWithRights.invitationAccepted
            ? Statuses.Accepted
            : Statuses.Pending
        }
      />
    ),
  };
};

const DelegeesDataTable = (props: IDelegeesDataTable) => {
  const {
    onAssignVehicleClicked,
    onInviteFleetMemberClicked,
    onRevokeClicked,
    onDeleteClicked,
    parkingProducts,
    getDelegees,
    useSingleDirectVehicleAssignment
  } = props;
  const { t } = useTranslation([
    "myFleet",
    "globals",
    "dataTable",
    "fleetManagerTable",
  ]);

  const { fleetManagerState } = useFleetManagerContext();
  const dataTableContext = useDataTableContext();
  const { myFleetContextState, setSorting } = useMyFleetContext();

  const { appState } = useAppContext();
  const { delegees } = parkingProducts;

  const [selectedParkingRightsCount, setSelectedParkingRightsCount] =
    useState(0);

  const getRequestModel = (): WithFiltersAndPaging<ForFleetManager> => {
    const request: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageNumber: dataTableContext.state.pageNumber!,
        pageSize: dataTableContext.state.pageSize!,
      },
      filters: [
        {
          key: FmpFilters.sortBy,
          type: FilterType.QueryParam,
          value: myFleetContextState.sortBy,
        },
        {
          key: FmpFilters.orderDesc,
          type: FilterType.QueryParam,
          value: myFleetContextState.orderDesc?.toString(),
        },
        ...(dataTableContext.state.filters as DataTableContextFilters[]),
      ],
    };

    return request;
  };

  const countAndSetSelectedParkingRights = () => {
    let parkingRightIds = getSelectedParkingRightIds(
      fleetManagerState,
      dataTableContext.state,
      delegees
    );

    if (parkingRightIds.length != selectedParkingRightsCount) {
      setSelectedParkingRightsCount(parkingRightIds.length);
    }
  };

  const fetchDelegees = () => {
    if (appState.user.seasonTicketOwnerCrmId) {
      getDelegees(getRequestModel());
      countAndSetSelectedParkingRights();
    }
  };

  const memoizedFetch = useCallback(
    (): void => fetchDelegees(),
    // eslint-disable-next-line
    [
      dataTableContext.state.pageSize,
      dataTableContext.state.pageNumber,
      myFleetContextState.orderDesc,
      myFleetContextState.sortBy,
      appState.user.seasonTicketOwnerCrmId,
      appState.selectedLanguage,
    ]
  );

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  useEffect(() => {
    countAndSetSelectedParkingRights();
  }, [fleetManagerState.selectedRowKeys]);

  const columns: IFilteredColumn<IDelegeeDataTableModel>[] =
    filteredColumnObjectBuilder.buildDelegeesColumns(
      t,
      getParkingProductOptions(parkingProducts.parkingProducts?.data ?? [])
    );

  const getTableRows = (): IDelegeeDataTableModel[] => {
    if (
      !isNull(delegees.pages) &&
      !isUndefined(delegees.pages) &&
      !isUndefined(delegees.pages[dataTableContext.state.pageNumber!])
    ) {
      return delegees.pages[dataTableContext.state.pageNumber!].data?.map(
        _createData
      ) as IDelegeeDataTableModel[];
    }

    return [];
  };

  const getLoadingState = () => {
    if (
      !isNull(delegees.pages) &&
      !isUndefined(delegees.pages) &&
      !isUndefined(delegees.pages[dataTableContext.state.pageNumber!])
    ) {
      return delegees.pages[dataTableContext.state.pageNumber!].loading;
    }

    return false;
  };

  const isLoading = getLoadingState();
  const rows: IDelegeeDataTableModel[] = getTableRows();

  const getRegistrationIdByTableRow = (
    focusedRow: IDataTableRow | null
  ): string | null => {
    return focusedRow?.rowKey;
  };

  const isResendInviteDisabled = (focusedRow: IDataTableRow | null) => {
    const delegeeRow = (focusedRow as IDelegeeDataTableModel) || null;

    return (
      delegeeRow?.products == null ||
      delegeeRow.status.props?.type !== Statuses.Pending
    );
  };

  const onSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IDelegeeDataTableModel
  ) => {
    setSorting(property.toString());
  };

  return (
    <FleetManagerDataTable
      subHeading={props.subHeading}
      columns={columns}
      rows={rows}
      isLoading={isLoading}
      totalPages={delegees.totalPages}
      getRegistrationIdFromTableRow={getRegistrationIdByTableRow}
      isResendInviteDisabled={isResendInviteDisabled}
      onFilterClicked={fetchDelegees}
      onResetFiltersClicked={fetchDelegees}
      onSort={onSort}
      orderBy={myFleetContextState.sortBy ?? ""}
      order={myFleetContextState.orderDesc ? "desc" : "asc"}
      translationOverrideKey={"delegees"}
      heading={{
        options: {
          useHeading: true,
          showHeadingCount: false,
        },
        title: t("myFleet"),
      }}
      filtering={{
        options: {
          useFilters: true,
        },
      }}
      actions={{
        options: {
          useActions: true,
        },
        add: {
          useAdd: !useSingleDirectVehicleAssignment,
          showAdd: !useSingleDirectVehicleAssignment,
          addLabel: t("fleetManagerTable:addDelegee"),
          onAddClicked: onInviteFleetMemberClicked,
        },
        delete: {
          useDelete: true,
          showDelete: fleetManagerState.selectedRowKeys.length > 0,
          deleteLabel: `${t("globals:delete")} (${
            fleetManagerState.selectedRowKeys.length
          })`,
          onDeleteClicked: onDeleteClicked,
        },
        revoke: {
          useRevoke: true,
          showRevoke: selectedParkingRightsCount > 0,
          revokeLabel: `${t("globals:revoke")} (${selectedParkingRightsCount})`,
          onRevokeClicked: onRevokeClicked,
        },
        menu: {
          useMenu: useSingleDirectVehicleAssignment,
          showMenu: useSingleDirectVehicleAssignment,          
          menuItems: [
            {
              label: t("fleetManagerTable:inviteFleetMember"),
              action: onInviteFleetMemberClicked,
              icon: <PersonIcon/>
            },
            {
              label: t("fleetManagerTable:assignVehicle"),
              action: onAssignVehicleClicked,
              icon: <VehicleIcon/>
            }
          ]
        }
      }}
    />
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;
  const { environment } = state;

  const useSingleDirectVehicleAssignment = environment.featureFlags?.find(
    (item) => item.name === "UseSingleDirectVehicleAssignment"
    );

  return {
    parkingProducts,
    useSingleDirectVehicleAssignment: useSingleDirectVehicleAssignment?.value ?? false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDelegees: (request: WithFiltersAndPaging<ForFleetManager>) =>
    dispatch(parkingProductsActions.getDelegees(request)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DelegeesDataTable);
