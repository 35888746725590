import axios from "../axios";
import { AxiosResponse } from "axios";
import aiApi from "../api/ai.api";

type AiService = {
  getConnectionString: () => Promise<AxiosResponse<string>>;
};

const getConnectionString = (): Promise<AxiosResponse<string>> =>
  axios.get(aiApi.getConnectionStringUrl());

export default {
  getConnectionString,
} as AiService;
