import React from "react";
import { createContext, useReducer } from "react";
import { ICheckParkingRight } from "../components/DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { importVehiclesTypes } from "./store/actions/importVehicles.types";
import reducer, {
  initialState,
  ImportVehiclesState,
  VehicleData,
} from "./store/reducers/importVehicles.reducer";

interface ImportVehiclesContext {}

export interface ImportVehiclesContextState {
  importVehiclesState: ImportVehiclesState;
  setVehicleData: (data: VehicleData[],) => void;
  setCheckParkingRights: (checkParkingRights: ICheckParkingRight[]) => void;
  setProcessing: (isProcessing: boolean) => void;
}

export const ImportVehiclesCtx =
  createContext<ImportVehiclesContextState>(
    {} as ImportVehiclesContextState
  );

const ImportVehiclesContextProvider: React.FC<ImportVehiclesContext> = ({
  children,
}) => {
  const [ImportVehiclesState, dispatch] = useReducer(reducer, initialState);

  const setVehicleData = (data: VehicleData[]) =>
    dispatch({
      type: importVehiclesTypes.SET_VEHICLES_DATA,
      payload: data,
    });

  const setCheckParkingRights = (parkingRights: ICheckParkingRight[]) =>
    dispatch({
      type: importVehiclesTypes.SET_SELECTED_VEHICLES_PARKING_RIGHTS,
      payload: parkingRights,
    });

  const setProcessing = (isProcessing: boolean) =>
    dispatch({
      type: importVehiclesTypes.SET_VEHICLES_PROCESSING,
      payload: isProcessing,
    });

  const value: ImportVehiclesContextState = {
    importVehiclesState: ImportVehiclesState,
    setVehicleData,
    setCheckParkingRights,
    setProcessing,
  };

  return (
    <ImportVehiclesCtx.Provider value={value}>
      {children}
    </ImportVehiclesCtx.Provider>
  );
};

export default ImportVehiclesContextProvider;
