import { AssignedVehicleRow } from "../../../containers/FleetManager/MyProducts/ParkingRightsDataTable/VehiclesTable";
import { fleetManagerContextTypes } from "../actions/fleetManagerContext.types";

export enum DelegationType {
  Single = "single",
  Multiple = "multiple",
}

export enum AssignmentType {
  Single = "single",
  Multiple = "multiple",
}

export type FleetManagerContextState = {
  delegationType: DelegationType;
  assignmentType: AssignmentType;
  dragAndDropView: boolean;
  vehicleDragAndDropView: boolean;
  showDelegeeDetails: boolean;
  showAssignVehicle: boolean;
  showWhitelistVehicles: boolean;
  showDeleteDelegeeWarning: boolean;
  showBatchDeleteDelegationsWarning: boolean;
  showBatchRevokeParkingRightsWarning: boolean;
  showBatchRevokeParkingVehicleAssignment: boolean;
  showRevokeParkingRightsWarning: boolean;
  showRevokeParkingVehicleAssignment: boolean;
  showDeleteVehiclesAssignment: boolean;
  showCloseImportWarning: boolean;
  selectedRowKeys: string[];
  selectedDelegeeRowKey: string;
  isEditDelegeeMode: boolean;
  isEditVehicleAssignmentMode: boolean;
  numberPlateWarning: string | undefined;
  numberPlateError: string | undefined;
  selectedRegistrationId: string;
  selectedVehicleAssignment?: AssignedVehicleRow;
  hasUnlimitedEntryRights: boolean;
};

export type FleetManagerContextAction = {
  type: string;
  payload?: number | string | string[] | DelegationType | boolean | AssignedVehicleRow;
};

export const initialState: FleetManagerContextState = {
  delegationType: DelegationType.Single,
  assignmentType: AssignmentType.Single,
  dragAndDropView: false,
  vehicleDragAndDropView: false,
  showDelegeeDetails: false,
  showAssignVehicle: false,
  showWhitelistVehicles: false,
  showDeleteDelegeeWarning: false,
  showBatchDeleteDelegationsWarning: false,
  showBatchRevokeParkingRightsWarning: false,
  showRevokeParkingRightsWarning: false,
  showDeleteVehiclesAssignment: false,
  showBatchRevokeParkingVehicleAssignment: false,
  showRevokeParkingVehicleAssignment: false,
  showCloseImportWarning: false,
  isEditDelegeeMode: false,
  isEditVehicleAssignmentMode: false,
  numberPlateWarning: undefined,
  numberPlateError: undefined,
  selectedRegistrationId: "",
  selectedRowKeys: [],
  selectedDelegeeRowKey: "",
  hasUnlimitedEntryRights: false,
};

export default (
  state: FleetManagerContextState = initialState,
  action: FleetManagerContextAction
): FleetManagerContextState => {
  switch (action.type) {
    case fleetManagerContextTypes.SET_SELECTED_REGISTRATION_ID:
      return {
        ...state,
        selectedRegistrationId: action.payload as string,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_ADD_DELEGEE:
      return {
        ...state,
        showDelegeeDetails: !state.showDelegeeDetails,
        delegationType: DelegationType.Single,
        dragAndDropView: false,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_ASSIGN_VEHICLE:
      return {
        ...state,
        showAssignVehicle: !state.showAssignVehicle,
        isEditVehicleAssignmentMode: false,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_WHITELIST_VEHICLES:
      return {
        ...state,
        showWhitelistVehicles: !state.showWhitelistVehicles,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_EDIT_VEHICLE_ASSIGNMENT:
      return {
        ...state,
        showAssignVehicle: !state.showAssignVehicle,
        isEditVehicleAssignmentMode: true,
        selectedVehicleAssignment: action.payload as AssignedVehicleRow,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_EDIT_DELEGEE:
      return {
        ...state,
        delegationType: DelegationType.Single,
        dragAndDropView: false,
        showDelegeeDetails: !state.showDelegeeDetails,
        isEditDelegeeMode: !state.isEditDelegeeMode,
        selectedRegistrationId: action.payload as string,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_DELETE_DELEGEE:
      return {
        ...state,
        showDeleteDelegeeWarning: !state.showDeleteDelegeeWarning,
      };
    case fleetManagerContextTypes.SET_SELECTED_PARKINGRIGHT_IDS:
      return {
        ...state,
        selectedRowKeys: action.payload as string[],
      };
    case fleetManagerContextTypes.SET_SELECTED_ROW_KEY:
      return {
        ...state,
        selectedDelegeeRowKey: action.payload as string,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_BATCH_REVOKE_PARKINGRIGHTS:
      return {
        ...state,
        showBatchRevokeParkingRightsWarning: !state.showBatchRevokeParkingRightsWarning,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_BATCH_REVOKE_VEHICLE_ASSIGNMENT:
      return {
        ...state,
        showBatchRevokeParkingVehicleAssignment: !state.showBatchRevokeParkingVehicleAssignment,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_REVOKE_PARKINGRIGHTS:
      return {
        ...state,
        showRevokeParkingRightsWarning: !state.showRevokeParkingRightsWarning,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_REVOKE_VEHICLE_ASSIGNMENT:
      return {
        ...state,
        showRevokeParkingVehicleAssignment: !state.showRevokeParkingVehicleAssignment,
        selectedVehicleAssignment: action.payload as AssignedVehicleRow
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_DELETE_VEHICLE_ASSIGNMENT:
      return {
        ...state,
        showDeleteVehiclesAssignment: !state.showDeleteVehiclesAssignment,
        selectedVehicleAssignment: action.payload as AssignedVehicleRow
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_BATCH_DELETE_DELEGATIONS:
      return {
        ...state,
        showBatchDeleteDelegationsWarning: !state.showBatchDeleteDelegationsWarning,
      };
    case fleetManagerContextTypes.SET_DELEGATION_TYPE:
      return {
        ...state,
        delegationType: action.payload as DelegationType,
      };
    case fleetManagerContextTypes.SET_ASSIGNMENT_TYPE:
      return {
        ...state,
        assignmentType: action.payload as AssignmentType,
      };
    case fleetManagerContextTypes.SET_DRAG_AND_DROP_VIEW:
      return {
        ...state,
        dragAndDropView: action.payload as boolean,
      };
    case fleetManagerContextTypes.SET_VEHICLE_DRAG_AND_DROP_VIEW:
      return {
        ...state,
        vehicleDragAndDropView: action.payload as boolean,
      };
    case fleetManagerContextTypes.SET_NRPLATE_WARNING:
      return {
        ...state,
        numberPlateWarning: action.payload as string | undefined,
      };
    case fleetManagerContextTypes.SET_NRPLATE_ERROR:
      return {
        ...state,
        numberPlateError: action.payload as string | undefined,
      };
    case fleetManagerContextTypes.TOGGLE_SHOW_IMPORT_WARNING:
      return {
        ...state,
        showCloseImportWarning: !state.showCloseImportWarning,
      };    
    case fleetManagerContextTypes.RESET_FLEET_MANAGER_CONTEXT:
      return initialState;
    default:
      return state;
  }
};
